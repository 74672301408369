import { useEffect, useState, useRef } from 'react';

// import _ from 'lodash';

import { usePresenterOrAttendee } from '../context/AttendeeAndPresenterProvider';

import CustomGameButton from '../common/CustomGameButton';
import PlayerNameInput from './NameComponent';

import team1Name from '../../assets/teamMcIntyre.png';
import team2Name from '../../assets/teamCrown.png';

import caplytaLogo from '../../assets/caplytaLogo.png';

import waitForQuestionsUnbranded from '../../assets/waitForQuestionsUnbranded.png';
import waitForQuestionsBranded from '../../assets/waitForQuestionsBranded.png';
import thankYouBox from '../../assets/thankYouBox.png';

const GameComponent = () => {
  const { currentQuestion, gameState, submitResponse, questions, teamUser } = usePresenterOrAttendee();

  const [questionCategory, setQuestionCategory] = useState('bipolar');
  const [isIdleOrEnded, setIsIdleOrEnded] = useState(true);

  const [userAnswer, setUserAnswer] = useState(null);

  // const [disappearingOrder, setDisappearingOrder] = useState([]);
  // const [hiddenAnswers, setHiddenAnswers] = useState([]);
  const [remainingPoints, setRemainingPoints] = useState(100);
  const initialTimeoutRef = useRef();
  const disappearingIntervalRef = useRef();

  const disappearingOrderRef = useRef();
  const hiddenAnswersRef = useRef([]);
  const gameStateRef = useRef(gameState);

  useEffect(() => {
    gameStateRef.current = gameState;

    if (gameState.questionState === 'closed') {
      console.log('clearing interval for bc of closed question');
      clearAllIntervalsAndTimeouts();
      setRemainingPoints(100);
      hiddenAnswersRef.current = [];
      sessionStorage.removeItem('hiddenAnswerData');
    }
  }, [gameState]);

  useEffect(() => {
    setUserAnswer(null);

    // console.log('currentQuestion', currentQuestion, teamUser, teamUser?.attendeeName);

    if (currentQuestion && teamUser?.attendeeName) {
      const hiddenAnswerData = JSON.parse(sessionStorage.getItem('hiddenAnswerData'));

      const incorrectAnswers = currentQuestion.answers
        .filter((answer) => !answer.isCorrect)
        .sort((a, b) => {
          return a.disappearOrder - b.disappearOrder;
        });

      const incorrectAnswerIds = incorrectAnswers.map((answer) => answer.id);

      if (hiddenAnswerData) {
        clearAllIntervalsAndTimeouts();

        // console.log('HHHHHHH hiddenAnswerData', hiddenAnswerData);

        disappearingOrderRef.current = incorrectAnswerIds.filter((id) => !hiddenAnswerData.includes(id));
        // console.log('disappearingOrderRef.current ---> ', disappearingOrderRef.current);

        hiddenAnswersRef.current = hiddenAnswerData;
        let remainingPointsCount = 100;
        // for each hidden answer, reduce the remainingPointsCount by half
        hiddenAnswerData.forEach(() => {
          remainingPointsCount = Math.ceil(remainingPointsCount / 2);
        });
        setRemainingPoints(remainingPointsCount);
      } else {
        // filter the incorrect answers and sort them by the order they should disappear

        // setDisappearingOrder(incorrectAnswers.map((answer) => answer.id));
        disappearingOrderRef.current = incorrectAnswerIds;
        console.log('incorrectAnswers', incorrectAnswers);
      }

      // console.log('@######@!Q@SEGADFGSBDFBFZ');
      // console.log('gameState', gameState, 'teamUser', teamUser);
      if (gameState.questionState === 'open' && gameState.state !== 'ended' && teamUser?.attendeeName) {
        // start an initial timer of 5 seconds before the first answer disappears then every 3 seconds after that

        // console.log('asdfasdfasdfasdf', initialTimeoutRef);
        if (!initialTimeoutRef.current) {
          const initialTimeoutDelay = hiddenAnswersRef.current.length ? 0 : 15000;

          console.log('XXXXX starting interval,', initialTimeoutDelay, 'XXXXX');
          initialTimeoutRef.current = setTimeout(() => {
            console.log('XXXXX starting interval 3s for disappearing questions XXXXX');
            disappearingIntervalRef.current = setInterval(handleDisappearingAnswers, 3000);
          }, initialTimeoutDelay);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentQuestion, teamUser, gameState]);

  useEffect(() => {
    if (gameState.state === 'initial') {
      setQuestionCategory('unbranded');
      // setDisappearingOrder([]);
      disappearingOrderRef.current = null;
      hiddenAnswersRef.current = [];
      setRemainingPoints(100);
    }

    if (
      gameState.state === 'initial' ||
      !gameState.state ||
      !currentQuestion ||
      ['closed', 'new'].includes(gameState.questionState) ||
      gameState.state === 'ended'
    ) {
      setIsIdleOrEnded(true);
    } else {
      setIsIdleOrEnded(false);
    }

    if (currentQuestion) {
      // console.log('questions', questions, currentQuestion.id);

      const nextQuestion = questions[currentQuestion.id + 1];
      if (gameState.questionState !== 'closed' && currentQuestion.category !== questionCategory) {
        // console.log('NOT CLOSED: currentQuestion.category', currentQuestion.category, currentQuestion);
        setQuestionCategory(currentQuestion.category.toLowerCase());
      } else if (gameState.questionState === 'closed' && nextQuestion && nextQuestion.category !== questionCategory) {
        // console.log(
        //   'CLOSED!!! nextQuestion.category',
        //   nextQuestion.category,
        //   questionCategory,
        //   nextQuestion,
        //   'xxxxxxx',
        //   currentQuestion
        // );
        setQuestionCategory(nextQuestion.category.toLowerCase());
      }

      // console.log('!!!CCCCCCCC', gameState, 'teamUser', teamUser);
      // if (gameState.questionState === 'open' && gameState.state !== 'ended' && teamUser?.attendeeName) {
      //   // start an initial timer of 5 seconds before the first answer disappears then every 3 seconds after that
      //   if (!initialTimeoutRef.current) {
      //     console.log('XXXXX starting interval 5s XXXXX');
      //     initialTimeoutRef.current = setTimeout(() => {
      //       console.log('XXXXX starting interval 3s for disappearing questions XXXXX');
      //       disappearingIntervalRef.current = setInterval(handleDisappearingAnswers, 3000);
      //     }, 5000);
      //   }
      // }
      // if (gameState.questionState === 'closed') {
      //   console.log('clearing interval for bc of closed question');
      //   clearAllIntervalsAndTimeouts();
      //   setRemainingPoints(100);
      //   sessionStorage.removeItem('hiddenAnswerData');
      // }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameState, currentQuestion, questionCategory, questions, teamUser]);

  const clearAllIntervalsAndTimeouts = () => {
    clearTimeout(initialTimeoutRef.current);
    clearInterval(disappearingIntervalRef.current);
    initialTimeoutRef.current = null;
    disappearingIntervalRef.current = null;
  };

  const handleDisappearingAnswers = () => {
    const gameStateData = gameStateRef.current;
    const disappearingOrderData = disappearingOrderRef.current;
    // console.log('gameState', gameStateData, 'disappearingOrder', disappearingOrderData);
    // debugger;
    if (gameStateData.questionState === 'open') {
      // console.log('###################');
      // console.log('handleDisappearingAnswers', disappearingOrderData);
      // console.log('###################');

      if (disappearingOrderData?.length) {
        const answerId = disappearingOrderData.shift();
        console.log('interval', answerId, disappearingOrderData);

        hiddenAnswersRef.current.push(answerId);
        console.log('asdfa 09098098 hiddenAnswerData', hiddenAnswersRef.current);
        sessionStorage.setItem('hiddenAnswerData', JSON.stringify(hiddenAnswersRef.current));
        // setDisappearingOrder(disappearingOrder);
        setRemainingPoints((prev) => {
          // console.log('XXXXXX remainingPoints', prev, 'nextRemainingPoints', disappearingOrderData.length ? Math.ceil(prev / 2) : 0);
          return disappearingOrderData.length ? Math.ceil(prev / 2) : 0;
        });
        if (!disappearingOrderData.length) {
          console.log('handleDisappearingAnswers clearing interval for disappearing answers');

          clearAllIntervalsAndTimeouts();
        }
      }
    }
  };

  const renderWaitingForQuestions = () => {
    if (questionCategory === 'branded') {
      return (
        <img
          src={waitForQuestionsBranded}
          alt="Waiting for questions"
          // style={{ width: '90%', marginTop: 30, marginBottom: 20 }}
          className="inbetween-screens-img"
        />
      );
    } else {
      return (
        <img
          src={waitForQuestionsUnbranded}
          alt="Waiting for questions"
          // style={{ width: '90%', marginTop: 30, marginBottom: 20 }}
          className="inbetween-screens-img"
        />
      );
    }
  };

  const renderThankYou = () => {
    return (
      <>
        <img
          src={thankYouBox}
          alt="Thank you"
          // style={{ marginBottom: 120 }}
          className="inbetween-screens-img"
          onClick={() => window.open('https://www.caplytahcp.com/', '_blank')}
        />
        {renderFooter()}
      </>
    );
  };

  const renderFooter = () => {
    return (
      <p className={'brandedFooter'}>
        Please see Important Safety Information, including <br />
        Boxed Warnings, throughout this presentation.
      </p>
    );
  };

  const handleClick = (answer) => {
    if (userAnswer) return;
    setUserAnswer(answer);
    const points = answer.isCorrect ? remainingPoints : 0;
    // debugger;
    console.log('clearing interval for bc of answer click');
    clearAllIntervalsAndTimeouts();
    submitResponse(answer.id, currentQuestion.id, points);
  };

  if (!teamUser?.attendeeName) {
    return <PlayerNameInput />;
  }

  return (
    <div className={`flex direction-column ${questionCategory}`} style={{ textAlign: 'center' }}>
      {/* <img src={ictLogo} alt="ICT Logo" style={{ width: '90%', marginTop: 30, marginBottom: 20 }} /> */}
      {/* <h2 style={{ marginBottom: 0, fontWeight: 'bold', fontSize: '3vh' }}>Bipolar I or Bipolar II?</h2> */}
      <img src={teamUser?.groupId === 1 ? team1Name : team2Name} alt="Team Logo" className="team-name-img" />
      {isIdleOrEnded ? (
        <div className="inbetween-screens-wrapper">
          {gameState.state === 'ended' ? renderThankYou() : renderWaitingForQuestions()}
        </div>
      ) : (
        <>
          <div className="question-wrapper" style={{ paddingTop: questionCategory === 'branded' && 5 }}>
            {questionCategory === 'branded' && (
              <img src={caplytaLogo} alt="Caplyta Logo" className="caplyta-logo" style={{ width: '40vw' }} />
            )}
            <p className="question-text" dangerouslySetInnerHTML={{ __html: currentQuestion.text }} />
          </div>

          {userAnswer ? (
            <h4 style={{ textAlign: 'center', height: 8, fontSize: '1.2rem' }}>
              {userAnswer.isCorrect ? 'CORRECT' : 'INCORRECT'}
            </h4>
          ) : (
            <div style={{ height: 20 }} />
          )}

          <div
            className="flex direction-column"
            style={{
              // display: 'flex',
              // flexDirection: 'column',
              justifyContent: 'space-between'
              // height: '100%',
              // minHeight: '50vh'
              // border: '1px solid yellow'
              // backgroundColor: 'pink'
            }}
          >
            <div className="answer-wrapper" style={{ marginBottom: questionCategory === 'unbranded' && 10 }}>
              {currentQuestion.answers.map((answer, index) => {
                return (
                  <CustomGameButton
                    key={index}
                    text={answer.text}
                    isCorrect={userAnswer && userAnswer.isCorrect}
                    isSelected={userAnswer && userAnswer.id === answer.id}
                    onClick={() => handleClick(answer)}
                    answerLetter={String.fromCharCode(65 + index)}
                    hideButton={
                      (userAnswer && userAnswer.id !== answer.id) || hiddenAnswersRef.current.includes(answer.id)
                    }
                    category={questionCategory}
                  />
                );
              })}
            </div>
            {questionCategory === 'branded' && renderFooter()}
          </div>
        </>
      )}
    </div>
  );
};

export default GameComponent;
