import { usePresenterOrAttendee } from '../context/AttendeeAndPresenterProvider';
import { LinearProgress } from '@mui/material';

const QuestionComponent = () => {
  const { currentQuestion, userAnswers } = usePresenterOrAttendee();

  console.log('currentQuestion', currentQuestion, 'userAnswers', userAnswers);

  if (!currentQuestion) {
    return '';
  }

  return (
    <div style={{ marginTop: 20 }}>
      <span dangerouslySetInnerHTML={{ __html: currentQuestion.text }} style={{ fontSize: 28 }} />

      <h4>Total answers: {userAnswers.totalAnswerCount}</h4>

      {currentQuestion.answers.map((answer, index) => {
        const percentage = userAnswers?.[answer.id] || 0;
        return (
          <div key={index}>
            <h3>
              {answer.text} ({percentage}%)
            </h3>
            <LinearProgress variant="determinate" value={percentage} />
          </div>
        );
      })}
    </div>
  );
};

export default QuestionComponent;
