import React, { useState, useEffect, useContext } from 'react';
import io from 'socket.io-client';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import LoadingComponent from '../common/LoadingComponent';

import config from '../../config';
// console.log('slidesConfigData', slidesConfigData);

const PresenterOrAttendeeContext = React.createContext(null);

const UNIQUEATTENDEEID = 'UNIQUEATTENDEEID';
export const AttendeeAndPresenterProvider = ({ isPresenterOrLeaderBoard, children }) => {
  const [connected, setConnected] = useState(false);
  const [socket, setSocket] = useState();
  const [gameState, setGameState] = useState({});
  const [questions, setQuestions] = useState({});
  const [questionIds, setQuestionIds] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [userAnswers, setUserAnswers] = useState({});
  const [attendeeId, setAttendeeId] = useState(null);
  const [teamUser, setTeamUser] = useState(null);
  const [teamScores, setTeamScores] = useState({
    1: 0,
    2: 0
  });
  const [topPlayers, setTopPlayers] = useState([]);

  const [prevQuestion, setPrevQuestion] = useState('initial');

  useEffect(() => {
    const newSocket = io.connect(config.baseUrl);
    setSocket(newSocket);

    let UniqueAttendeeId = sessionStorage.getItem(UNIQUEATTENDEEID);

    if (!isPresenterOrLeaderBoard) {
      if (!UniqueAttendeeId) {
        UniqueAttendeeId = uuidv4();
        sessionStorage.setItem(UNIQUEATTENDEEID, UniqueAttendeeId);
      }
      setAttendeeId(UniqueAttendeeId);
    }

    newSocket.on('connect', () => {
      // console.log('connecting and joining');

      // join a group
      newSocket.emit('join');

      if (!isPresenterOrLeaderBoard) {
        newSocket.emit('getGroupId', { attendeeId: UniqueAttendeeId });
      } else {
        newSocket.emit('getLeaderBoard');
      }
      newSocket.emit('getState');
      newSocket.emit('getQuestions');

      setConnected(true);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // console.log('in socket useEffect', socket);
    if (socket) {
      socket.on('gameState', (gameStateData) => {
        console.log('gameStateData', gameStateData);
        setGameState(gameStateData);
      });

      socket.on('questions', (questionsData) => {
        // console.log('questionsData', questionsData);
        setQuestionIds(_.map(questionsData, 'id'));
        setQuestions(_.keyBy(questionsData, 'id'));
      });

      socket.on('currentQuestion', (currentQuestionId) => {
        // console.log('currentQuestionId', currentQuestionId);
        setCurrentQuestion(questions[currentQuestionId]);
      });

      socket.on('userAnswers', (userAnswers) => {
        // console.log('ZZZZZZ userAnswers', userAnswers);
        setUserAnswers(userAnswers);
      });

      socket.on('groupId', (userGroup) => {
        // console.log('ZZZZZZ USER GROUP', userGroup);
        // setUserAnswers(userAnswers);
        setTeamUser(userGroup);
      });

      socket.on('leaderBoard', (leaderBoardData) => {
        // console.log('LLLLL leaderBoardData', leaderBoardData);
        const scores = leaderBoardData.teamScores;
        setTeamScores((prev) => ({ ...prev, ...scores }));
        console.log('leaderBoardData.topPlayers', leaderBoardData.topPlayers);
        setTopPlayers(leaderBoardData.topPlayers);
      });

      socket.on('resetUser', () => {
        if (!isPresenterOrLeaderBoard) {
          console.log('resetUser');
          setTeamUser(null);
          // reset the unique id
          const UniqueAttendeeId = uuidv4();
          sessionStorage.setItem(UNIQUEATTENDEEID, UniqueAttendeeId);
          setAttendeeId(UniqueAttendeeId);

          sessionStorage.removeItem('hiddenAnswerData');
          // socket.emit('getGroupId', { attendeeId: UniqueAttendeeId });

          //   const randomTime = generateRandomNumber();
          //   console.log('on resetUser randomTime', randomTime);

          //  requestGroupId(UniqueAttendeeId, socket)
          socket.emit('getGroupId', { attendeeId: UniqueAttendeeId });
        }
      });

      socket.on('disconnect', () => {
        setConnected(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);

  useEffect(() => {
    // console.log(
    //   'in gameState useEffect',
    //   'gamestate:',
    //   gameState,
    //   'questions',
    //   questions,
    //   'questionIds:',
    //   questionIds,
    //   'currentQuestion',
    //   currentQuestion
    // );
    if ((gameState.questionId && questions && !currentQuestion) || gameState.questionId !== currentQuestion?.id) {
      // console.log('setting currentQuestion', gameState.questionId);
      setCurrentQuestion(questions[gameState.questionId]);
    }
  }, [gameState, questions, questionIds, currentQuestion]);

  // const generateRandomNumber = () => {
  //   // return Math.floor(Math.random() * (max - min + 1) + min);
  //   return _.random(200, 2000);
  // };

  // const requestGroupId = (attendeeId, socketObj) => {
  //   return () => {
  //     socketObj.emit('getGroupId', { attendeeId });
  //   };
  // };

  const startGame = () => {
    socket.emit('startGame', { questionId: questionIds[0] });
  };

  const nextQuestion = () => {
    const nextQuestionId = questionIds[questionIds.indexOf(currentQuestion.id) + 1];
    setUserAnswers({});
    resetAnswers();
    socket.emit('nextQuestion', { nextQuestionId });
  };

  // const nextQuestion = () => {
  //   const nextQuestionId = questionIds[questionIds.indexOf(currentQuestion.id) + 1];
  //   socket.emit('nextQuestion', { nextQuestionId });
  // };

  // const closeQuestion = () => {
  //   socket.emit('closeQuestion');
  // };

  const resetAnswers = () => {
    socket.emit('resetAnswers');
  };

  const openQuestion = () => {
    socket.emit('openQuestion');
  };

  const closeQuestion = () => {
    // if its the last question we should jsut end the game
    if (questionIds.indexOf(currentQuestion.id) === questionIds.length - 1) {
      setPrevQuestion('initial');
      endGame();
    } else {
      setPrevQuestion(currentQuestion.id);
      socket.emit('closeQuestion');
    }
  };

  const submitResponse = (answerId, questionId, points) => {
    // console.log('submitResponse', answerId, questionId, attendeeId, 'userGroupId', teamUser.id, 'points', points);
    const userGroupId = teamUser.id;
    socket.emit('submitResponse', { answerId, questionId, attendeeId, points, userGroupId });
  };

  const endGame = () => {
    setPrevQuestion('initial');
    // setUserAnswers({});
    resetAnswers();
    socket.emit('endGame');
  };

  const resetGame = () => {
    setPrevQuestion('initial');
    // setUserAnswers({});
    resetAnswers();
    socket.emit('resetGame');
  };

  const submitName = (playerName) => {
    console.log(playerName);
    socket.emit('submitName', { playerName, groupUserId: teamUser.id });
    setTeamUser((prev) => {
      return { ...prev, attendeeName: playerName };
    });
  };

  if (connected) {
    return (
      <PresenterOrAttendeeContext.Provider
        value={{
          socket,
          gameState,
          questions,
          currentQuestion,
          startGame,
          nextQuestion,
          openQuestion,
          closeQuestion,
          prevQuestion,
          submitResponse,
          endGame,
          resetGame,
          userAnswers,
          attendeeId,
          setTeamUser,
          teamUser,
          submitName,
          teamScores,
          topPlayers
        }}
      >
        {children}
      </PresenterOrAttendeeContext.Provider>
    );
  }
  return <LoadingComponent />;
};

export const usePresenterOrAttendee = () => {
  const store = useContext(PresenterOrAttendeeContext);
  return store;
};
