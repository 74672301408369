import TopPlayers from './TopPlayers';
import QrCode from './QrCode';

import { AttendeeAndPresenterProvider } from '../context/AttendeeAndPresenterProvider';
import { TeamScore } from '../common';

const Leaderboard = () => {
  return (
    <AttendeeAndPresenterProvider isPresenterOrLeaderBoard>
      <div className="flex-center direction-column align-center leaderBoardContainer" style={{ padding: 50 }}>
        <TeamScore />
        <TopPlayers />
        <QrCode />
      </div>
    </AttendeeAndPresenterProvider>
  );
};
export default Leaderboard;
