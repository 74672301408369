import React, { useState } from 'react';
import { usePresenterOrAttendee } from '../context/AttendeeAndPresenterProvider';

import team1Logo from '../../assets/team_1.png';
import team2Logo from '../../assets/team_2.png';

import submitButton from '../../assets/submitButton.png';

const teamLogoMapping = {
  1: team1Logo,
  2: team2Logo
};

const PlayerNameInput = () => {
  const { submitName, teamUser } = usePresenterOrAttendee();

  // console.log('teamUser', teamUser);

  const [playerName, setPlayerName] = useState('');
  const [error, setError] = useState('');

  const handleInputChange = (e) => {
    setPlayerName(e.target.value);
    setError('');
  };

  const handleSubmit = () => {
    if (playerName.trim() === '') {
      setError('Name cannot be empty');
      return;
    } else {
      console.log('Player Name:', playerName);
      setError('');
    }
    submitName(playerName);
  };

  return (
    <div className="name-comp-wrapper">
      <div className="name-comp-team-logo">
        {teamUser?.groupId ? (
          <img src={teamLogoMapping[teamUser?.groupId]} alt="Team Logo" />
        ) : (
          <h1>Joining Team...</h1>
        )}
      </div>
      <div className="flex vertical-center horizontal-center" style={{ height: '80vh' }}>
        <div className="name-comp-input-wrapper">
          <h1 style={{ textAlign: 'center' }}>Player Name</h1>
          <input
            type="text"
            maxLength={10}
            placeholder="ENTER YOUR NAME"
            value={playerName}
            onChange={handleInputChange}
            className="name-comp-input"
          />
          {error && <p style={{ color: 'red' }}>{error}</p>}
          <div className="name-comp-btn-wrapper">
            <img src={submitButton} alt="Submit" className="name-comp-btn" onClick={handleSubmit} />
          </div>
          <p style={{ textAlign: 'center', marginTop: 40, fontWeight: 600 }}>
            By participating, you consent to <br /> your name being displayed and <br /> shown to other participants.
          </p>
        </div>
      </div>
    </div>
  );
};

export default PlayerNameInput;
