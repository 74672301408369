import React from 'react';

const CustomButton = ({ isCorrect, isSelected, answerLetter, text, onClick, hideButton, category }) => {
  const getClass = () => {
    if (isSelected) {
      return isCorrect ? `${category}-correct-button` : 'incorrect-button';
    }
    return '';
  };

  return (
    <div
      className={`answer-button ${getClass()}`}
      style={{ visibility: hideButton ? 'hidden' : 'visible', marginTop: '1rem' }}
      onClick={onClick}
    >
      <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
        <span className="answer-button-inner-letter">{answerLetter})</span>
        <span className="answer-button-inner-text">{text}</span>
      </div>
    </div>
  );
};

export default CustomButton;
