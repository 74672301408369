import _ from 'lodash';
import { usePresenterOrAttendee } from '../context/AttendeeAndPresenterProvider';

import leaderboardTitle from '../../assets/leaderboard-title.png';
import team1Logo from '../../assets/teamMcIntyre.png';
import team2Logo from '../../assets/teamCrown.png';
import presenter1Logo from '../../assets/team_1.png';
import presenter2Logo from '../../assets/team_2.png';

const teamImgMap = {
  1: team1Logo,
  2: team2Logo
};

const presenterImgMap = {
  1: presenter1Logo,
  2: presenter2Logo
};

const TeamScore = ({ isPresenter }) => {
  const { teamScores } = usePresenterOrAttendee();

  if (isPresenter) {
    return (
      <div>
        <div className="flex">
          {_.keys(teamScores).map((teamId) => {
            return (
              <div key={teamId}>
                <img src={presenterImgMap[teamId]} alt={`team${teamId}`} style={{ width: 200 }} />
                <h2 style={{ textAlign: 'center' }}>{teamScores[teamId]}</h2>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="teamLeaderBoard">
        <div className="leaderBoardTitle">
          <img src={leaderboardTitle} alt="leaderboardTitle" />
        </div>

        <div className="teamNames">
          {_.keys(teamScores).map((teamId) => {
            return (
              <div key={teamId} className={`team${teamId}`}>
                <img src={teamImgMap[teamId]} alt={`team${teamId}`} />
                <h2
                  style={{ textAlign: 'center', fontSize: '80px', lineHeight: '80px', color: '#ffffff', margin: '0px' }}
                >
                  {teamScores[teamId]}
                </h2>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default TeamScore;
