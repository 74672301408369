import { usePresenterOrAttendee } from '../context/AttendeeAndPresenterProvider';

const TopPlayers = () => {
  const { topPlayers } = usePresenterOrAttendee();

  console.log('topPlayers', topPlayers);

  return (
    <div className="topPlayers">
      <div className="top3">
          <div className="second">
            <h4>{topPlayers[1]?.userName}</h4>
            <h3>{topPlayers[1]?.points}</h3>
            <div className={`box team${topPlayers[1]?.teamId}`}>
              <h2>2</h2>
            </div>
          </div>

          <div>
            <div className="first">
              <h4>{topPlayers[0]?.userName}</h4>
              <h3>{topPlayers[0]?.points}</h3>
              <div className={`box team${topPlayers[0]?.teamId}`}>
                <h2>1</h2>
              </div>
            </div>
          </div>

          <div>
            <div className="third">
              <h4>{topPlayers[2]?.userName}</h4>
              <h3>{topPlayers[2]?.points}</h3>
              <div className={`box team${topPlayers[2]?.teamId}`}>
                <h2>3</h2>
              </div>
            </div>
          </div>
      </div>

      <div className="bottom3">
        {topPlayers.slice(3, 6).map((player, index) => {
          const playerTeamId = player.teamId;

          return (
            <div key={index} className={`team${playerTeamId}`}>
              <h3>
                {index + 4} &nbsp;<span style={{ fontWeight: 400 }}>{player.userName}</span>&nbsp; {player.points}
              </h3>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TopPlayers;
