import { BrowserRouter as Router, useRoutes } from 'react-router-dom';

import RapidGame from './RapidGame';
import Presenter from './Presenter';
import LeaderBoard from './LeaderBoard';

import '../styles/App.scss';

const AppRouter = () => {
  const routes = useRoutes([
    { path: '/', element: <RapidGame /> },
    { path: '/presenter', element: <Presenter /> },
    { path: '/results', element: <Presenter isReadOnly /> },
    { path: '/leaderboard', element: <LeaderBoard /> }
  ]);
  return routes;
};

const App = () => {
  return (
    <Router>
      <AppRouter />
    </Router>
  );
};

export default App;
