const baseUrl = process.env.REACT_APP_APIURL;

console.log('process.env', process.env);
console.log('baseUrl', baseUrl);

const config = {
  baseUrl: process.env.REACT_APP_APIURL || ''
};

export default config;
