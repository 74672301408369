import qrCode from '../../assets/qr-code.png';

const QrCode = () => {  
    return (
        <div className="qr-code">
            <img src={qrCode} alt={'QR Code'} style={{ width: 137 }}/>
        </div>
    );
};

export default QrCode;