import { AttendeeAndPresenterProvider } from '../context/AttendeeAndPresenterProvider';
import QuestionComponent from './QuestionComponent';

const BipolarGameComponent = () => {
  return (
    <AttendeeAndPresenterProvider>
      {/* <div style={{ padding: '40px 50px' }}> */}
      <QuestionComponent />
      {/* </div> */}
    </AttendeeAndPresenterProvider>
  );
};

export default BipolarGameComponent;
